<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { THEME_COLOR } from "~/constants";

export interface BaseInputProps {
  /**
   * Defines if input is disabled or not.
   */
  disabled?: boolean;
  /**
   * Defines if input value is invalid(has some form error).
   */
  hasError?: boolean;
  /**
   * Defines error message for input with error(consider `hasError: true`).
   */
  errorMessage?: string;
  /**
   * Defines the label value of the input.
   */
  label?: string;
  /**
   * Required. Used to create id's for the input/label.
   */
  name: string;
  /**
   * Base types are: 'text' | 'password' | 'tel' | 'color' | 'number' | 'email'.
   */
  type?: "text" | "password" | "number" | "tel" | "color" | "email";
  /**
   * Default to v-model
   */
  modelValue?: string | number | null;
  /**
   * Defines input's placeholder value.
   */
  placeholder?: string;
  /**
   * Defines if the input data is sensitive.
   *
   * Will convert the input to password type by default.
   */
  sensitive?: boolean;
  /**
   * Defines if the input is required.
   */
  required?: boolean;
  /**
   *
   *Hide an icon to show/hide the input data.
   */
  hideShowPasswordBtn?: boolean;
  /**
   * Defines if the input has a button to copy the input data to the clipboard.
   */
  hasClipboardCopy?: boolean;
  /**
   * Defines if the input is search type.
   *
   * Will show a search icon at the left.
   */
  search?: boolean;
  /**
   * Defines outline styles for the input.
   */
  outline?: boolean;
  /**
   * Defines class that will be applied to the root element.
   */
  rootClass?: string;
  /**
   * Defines class that will be applied to the label element.
   */
  labelClass?: string;
}
const props = withDefaults(defineProps<BaseInputProps>(), {
  type: "text",
  labelClass: "text-caption-medium",
  outline: false,
  disabled: false,
  sensitive: false,
  required: false,
});

const color = useColorMode();
const { formGroup } = useFormGroup();
const emit = defineEmits(["update:modelValue", "clear"]);
const inputValue = useVModel(props, "modelValue", emit);
const inputField = ref<HTMLInputElement | null>(null);
const hasError = computed(() => props.hasError || !!formGroup?.error?.value);
const isDisabled = computed(() => props.disabled || !!formGroup?.disabled?.value);

const isFocused = ref(false);
const isPasswordShown = ref(false);
const inputId = computed(() => `input-${props.name}`);
// @ts-expect-error FIXME:
const { copy: copyValue, isSupported: isCopySupported } = useClipboard({ source: inputValue });

function deleteValue() {
  emit("clear");
}

const inputClasses = computed(() => {
  return {
    baseInput__field_outline: props.outline,
    baseInput__field_focused: isFocused.value,
    baseInput__field_hasContent: Boolean(inputValue.value),
    baseInput__field_hasError: hasError.value,
  };
});

const inputType = computed(() => {
  if (props.sensitive)
    return isPasswordShown.value ? "text" : "password";

  return props.type;
});

const focus = () => inputField.value && inputField.value.focus();
const onBlur = () => inputField.value && inputField.value.blur();

defineExpose({ focus });
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div class="baseInput" :class="props.rootClass">
    <div v-if="props.label" class="flex items-center justify-between mb-2">
      <label
        :id="`input-label-${props.name}`"
        class="inline-block transition text-secondary"
        :class="[props.labelClass, props.required ? `after:content-['*'] after:text-[#d22018] after:mx-[2px]` : '']"
        :for="inputId"
        :aria-label="props.label"
      >
        {{ props.label }}
      </label>
      <slot name="label-suffix" />
    </div>
    <div class="baseInput__inner" :class="inputClasses">
      <slot name="prefix">
        <div v-if="props.search" class="baseInput__prefix">
          <IconSearch class="inline-block text-secondary" />
        </div>
      </slot>
      <slot name="input" :input-value="inputValue">
        <input
          v-bind="$attrs"
          :id="inputId"
          ref="inputField"
          v-model="inputValue"
          class="baseInput__field text-body-medium text-main"
          :data-test-id="inputId"
          :type="inputType"
          :placeholder="props.placeholder"
          :required="props.required"
          :disabled="isDisabled"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keyup.enter="onBlur"
        >
      </slot>
      <slot name="suffix">
        <div v-if="props.sensitive && !props.hideShowPasswordBtn" class="baseInput__suffix">
          <BaseButton
            type="icon"
            attr-type="button"
            name="toggle-password"
            :aria-controls="inputId"
            :aria-expanded="isPasswordShown"
            @click.stop="isPasswordShown = !isPasswordShown"
          >
            <IconEye class="inline-block" :is-dark-theme="color.value === THEME_COLOR.DARK" :active="isPasswordShown" />
          </BaseButton>
        </div>
        <div v-else-if="props.hasClipboardCopy" class="baseInput__suffix">
          <BaseButton type="icon" name="clipboard-copy" :disabled="!isCopySupported" :aria-controls="inputId" @click="copyValue()">
            <IconCopy class="inline-block" fill="var(--base-icon-color)" />
          </BaseButton>
        </div>
        <div v-else-if="inputValue && props.search" class="baseInput__suffix">
          <BaseButton name="clear-value" type="icon" title="Clear" :aria-controls="inputId" @click.prevent="deleteValue()">
            <IconSearchClose :width="16" :height="16" :is-dark-theme="color.value === THEME_COLOR.DARK" @click.prevent="deleteValue()" />
          </BaseButton>
        </div>
      </slot>
    </div>
    <div v-if="props.hasError && props.errorMessage" class="baseInput__errorMessage text-caption-medium">
      {{ props.errorMessage }}
    </div>
  </div>
</template>

<style lang="scss">
/* Fix Webkit autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.baseInput {
  @apply relative;
}

.baseInput__errorMessage {
  @apply text-right mt-0.5;

  color: var(--base-error-color);
}

.baseInput__inner {
  @apply relative w-full rounded flex items-center gap-3 transition;
  padding: 11px 16px;
  background-color: var(--base-card-bgColor);
  border: 1px solid transparent;

  &:focus-within,
  &.baseInput__field_focused,
  &.baseInput__field_outline {
    border-color: var(--base-card-bgSecondaryColor);
    .baseInput__field {
      @apply outline-none;
    }
  }

  &.baseInput__field_hasContent:has(input:invalid),
  &.baseInput__field_hasError {
    border: 1px solid var(--base-error-color);
  }
}

.baseInput__field {
  @apply w-full border-none shadow-none bg-transparent;

  &[type='color'] {
    padding: 0 !important;
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &::placeholder {
    @apply opacity-100 overflow-ellipsis;
    color: var(--base-secondary-color);
    text-indent: 0;
    transition: text-indent 0.27s ease;
  }
}

.baseInput__suffix {
  @apply flex items-center justify-center;
}

.baseInputGroup {
  @apply flex;

  > *:first-child .baseInput__inner {
    @apply rounded-r-none rtl:rounded-l-none rtl:rounded-r;
  }
  > *:last-child .baseInput__inner {
    @apply rounded-l-none rtl:rounded-r-none rtl:rounded-l;
  }
}
</style>
